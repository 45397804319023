require("bootstrap");

window.axios = require("axios");

window.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
window.axios.defaults.headers.common["Accept"] = "application/json";
window.axios.defaults.headers.common["Content-Type"] = "application/json";

// Request interceptor for API calls
window.axios.interceptors.request.use(
  async (config) => {
    const vuexData = JSON.parse(localStorage.getItem("vuex"));

    if (!vuexData || !vuexData.auth || !vuexData.auth.token) {
      return config;
    }

    config.headers["Authorization"] = `Bearer ${vuexData.auth.token}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

window.axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("vuex");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
