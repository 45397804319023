import Vue from "vue";
import VueRouter from "vue-router";

// const Login = () =>
//   import(
//     "../views/Login.vue" /* webpackChunkName: "resource/js/components/login" */
//   );
// const Register = () =>
//   import(
//     "../views/Register.vue" /* webpackChunkName: "resource/js/components/register" */
//   );

const Dashboard = () =>
  import(
    "../views/GivingCircleInvite.vue" /* webpackChunkName: "resource/js/components/dashboard" */
  );

Vue.use(VueRouter);

const routes = [
  // {
  //   name: "login",
  //   path: "/login",
  //   component: Login,
  //   meta: {
  //     middleware: "guest",
  //     title: `Login`,
  //   },
  // },
  // {
  //   name: "register",
  //   path: "/register",
  //   component: Register,
  //   meta: {
  //     middleware: "guest",
  //     title: `Register`,
  //   },
  // },
  {
    path: "/:id",
    component: Dashboard,
    meta: {
      title: `Giving Circle`,
    },
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, _, next) => {
  document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;

  next();
});

export default router;
